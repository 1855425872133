<template>
  <duv
      @click="goToDetail"
      :class="
      'request-card '.concat(isFavorite ? 'isFavorite ' : '') + this.cssClass
    "
  >

    <div class="provider-box">
      <img :src="provider.image" alt="">
      <div class="text-dark">
        <div>{{provider.name}}</div>
        <div class="text-black-50">{{moment(provider.created_at).format('ll')}}</div>
      </div>
    </div>

    <div>

      <div class="request-card-divider"></div>
    </div>


    <div class="request-card-row">
      <div class="heading">{{ $t('personal_information.service_provider_type') }}</div>
      <div class="value">{{ provider.account_type }}</div>
    </div>


    <div class="request-card-row" v-if="provider.location">
      <div class="heading">{{ $t('common.city') }}</div>
      <div class="value">{{ provider.location }}</div>
    </div>



    <div class="request-card-footer">
      <div class="btn">
        {{ $t('common.show_details') }}
      </div>
    </div>
  </duv>
</template>

<script>
export default {
  name: 'RequestProviderCard',
  props: {
    provider: {
      type: Object,
      default: () => ({
        id: 2,
        title: 'اسم الخدمة',
        excerpt: 'وصف صغير عن الخدمة',
        slug: 'اسم-الخدمة',
        city: 'صنعاء',
        country: 'اليمن',
        provider: 'الاسم العربي',
        max_people: '7',
        rate: '4.7',
        available_people: 1,
        price: 24,
        offer: {
          discount_percent: 16,
        },
        image:
            'https://images.unsplash.com/photo-1508050919630-b135583b29ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80',
      }),
    },
    image: String,
    cssClass: String,
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToDetail() {
      this.$router.push({
        name: 'requestProviderDetail',
        params: {
          id: this.provider.id,
        },
      });
    },
  },
};
</script>
