<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('requests.active.title')"
        :sub-title="$t('requests.active.desc')"
    />

    <div>
      <filter-header

          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderCurrencies"
          :content="content"
      />
    </div>

    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <div class="w-table w-table-4 d-flex flex-column request">
            <div class=" request-grid">

              <request-provider-card v-for="provider in getAllProviders"
                            :key="provider.id" :provider="provider"/>

            </div>
            <wameed-no-data
                v-if="getAllProviders && getAllProviders.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <wameed-pagination
              v-if="getTotalProviders"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalProviders.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import RequestProviderCard from "@/views/pages/requests/providers/components/RequestProviderCard";

export default {
  components: {
    RequestProviderCard,
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        page: 1,
        per_page: 8,
        city: '',
        type: '',
        order_by: 'name',
        search: '',
      },

      content: [
        {
          title: 'city',
          key: 'city',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },{
          title: 'service_provider_type',
          key: 'type',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllProviders: 'getAllRequests',
      getTotalProviders: 'getTotalRequests',
      getFilterData: 'getFilterData',
    }),

    translateKey() {
      return this.$router.currentRoute.params.type;
    }
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[0].data = newVal.cities ? newVal.cities : [];
        this.content[1].data = newVal.types ? newVal.types : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadFilterData('provider_requests');
  },
  updated() {
    this.$store.dispatch('loadRequestsCounts');
  },
  methods: {
    ...mapActions({
      loadProviders: 'loadProvidersRequests',
      loadFilterData: 'loadFilterData',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadProviders(this.filterData);
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderCurrencies(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

  },
};
</script>
